<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">试用订单</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="所属医院">
					<a-select placeholder="请选择" v-model="query.hospital_id">
						<a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="订单日期">
					<a-range-picker @change="onChange" />
				</a-form-model-item>
				<a-form-model-item label="订单编号">
					<a-input v-model="query.order_id" placeholder="订单编号	" />
				</a-form-model-item>
				<a-form-model-item label="昵称">
					<a-input v-model="query.nick_name" placeholder="昵称	" />
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input v-model="query.equipment_number" placeholder="设备编号	" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="order_id" style="margin-top: 14px" v-viewer>
			<span slot="area" slot-scope="text, record">
				<a-space>
					{{ record.hospital_name + '-' + record.department_name + '-' + record.hos_number }}
				</a-space>
			</span>
			<span slot="phone" slot-scope="text, record">
				<a-space>
					{{ record.phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') }}
				</a-space>
			</span>
			<span slot="time" slot-scope="text, record">
				<a-space>
					{{ record.time / 60 }}
				</a-space>
			</span>
		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			equipment_id: null,
			pagetotal: 0,
			order_id: null,
			visible: false,
			checkValue: [],
			hospitals: [],
			Rules: {
				money: [
					{
						required: true,
						message: '请填写金额',
						trigger: 'blur'
					}
				],
			},
			form: {},
			checkOptions: [{
				label: '使用中',
				value: 1
			},
			{
				label: '已完成',
				value: 2
			},
			{
				label: '已退款',
				value: 3
			},
			],
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '订单编号',
				dataIndex: 'order_id'
			},
			{
				title: '设备编号',
				dataIndex: 'equipment_number'
			},
			{
				title: '昵称',
				dataIndex: 'nickname'
			},
			{
				title: '手机号',
				scopedSlots: {
					customRender: 'phone',
				},
			},
			{
				title: '所属医院',
				scopedSlots: {
					customRender: 'area'
				}
			},
			{
				title: '使用时长(分)',
				dataIndex: "time"
			},
			{
				title: '创建时间',
				dataIndex: 'create_at_str'
			},
			],
			data: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
		this.getHospitals()
	},
	methods: {
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		onChange(a, b) {
			this.query.begin_time = b[0]
			this.query.end_time = b[1]
			this.getList()
		},
		getHospitals() {
			this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
				if (res.code === 0) {
					this.hospitals = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getList() {
			this.$post(domain + '/admin/v1/business/activityOrder', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
					console.log('ddddddddddd', res.data.total)
					console.log('ddddddddddd2', this.pagetotal)
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.equipment_id = null
			this.getList();
			console.log(query)
		},
		changeCheck(value) {
			console.log('value', value)
			if (value.length === 1) {
				this.query.type = value[0]
			}
			else if (value.length === 0) {
				delete this.query.type
				this.checkValue = []
			}
			else if (value.length === 2) {
				if (value[0] == this.query.type) {
					this.query.type = value[1]
					this.checkValue = [value[1]]
				} else {
					this.query.type = value[0]
					this.checkValue = [value[0]]
				}
			} else {
				this.checkValue = []
			}
			this.getList()
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
